import React, { useState } from "react";
import ClosedCard from "./ClosedCard";
import OpenCard from "./OpenCard";

const CardComponent = ({
  cardId,
  censoredPhoneNumber,
  description,
  iconList,
  initialComments = [],
}) => {
  const [open, setOpen] = useState(false);
  const [comments, setComments] = useState(initialComments);
  const [currentCensoredPhoneNumber, setCensoredPhoneNumber] =
    useState(censoredPhoneNumber);
  const [currentDescription, setDescription] = useState(description);
  const [currentIconList, setIconList] = useState(iconList);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <ClosedCard id={cardId} handleOpen={handleOpen} />
      {open && (
        <OpenCard
          cardId={cardId}
          censoredPhoneNumber={currentCensoredPhoneNumber}
          description={currentDescription}
          iconList={currentIconList}
          handleClose={handleClose}
          comments={comments}
          setComments={setComments}
          setCensoredPhoneNumber={setCensoredPhoneNumber}
          setDescription={setDescription}
          setIconList={setIconList}
        />
      )}
    </>
  );
};

export default CardComponent;
