import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  Backdrop,
  InputAdornment,
  Alert,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import iconDictionary from "./iconDictionary";
import { formatPhoneNumber } from "../utils/formatPhoneNumber";
import { debounce } from "lodash";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import names from "../utils/names.json";

const AddCardButton = ({ onCardCreated }) => {
  const [open, setOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [age, setAge] = useState("");
  const [city, setCity] = useState("");
  const [story, setStory] = useState("");
  const [impact, setImpact] = useState("");
  const [supportMessage, setSupportMessage] = useState("");
  const [focused, setFocused] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [statusError, setStatusError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [impactError, setImpactError] = useState("");
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setPhoneNumber("");
    setSelectedStatuses([]);
    setAge("");
    setCity("");
    setStory("");
    setImpact("");
    setSupportMessage("");
    setPhoneNumberError("");
    setStatusError("");
    setAgeError("");
    setImpactError("");
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };
    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, []);

  const handleStatusChange = (id) => {
    setSelectedStatuses((prev) =>
      prev.includes(id) ? prev.filter((status) => status !== id) : [...prev, id]
    );
  };

  const handlePhoneNumberChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, "");
    if (numericValue.length <= 11) {
      setPhoneNumber(numericValue);
    }
  };

  const handleAgeChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, "");
    if (numericValue.length <= 2) {
      setAge(numericValue);
    }
  };

  const handleTextChange = (setter) => (event) => {
    const value = event.target.value;
    const nonNumericValue = value.replace(/[0-9]/g, ""); // Remove números
    setter(nonNumericValue);
  };

  // Função para censurar nomes
  const censorNames = (text) => {
    const regex = new RegExp(`(?<!\\S)(${names.join("|")})(?!\\S)`, "gi");
    return text.replace(regex, (match) => "*".repeat(match.length));
  };

  const handleCreateCard = async () => {
    let hasError = false;

    if (username.trim() === "") {
      setUsernameError("Por favor, insira seu nome.");
      hasError = true;
    } else {
      setUsernameError("");
    }

    if (phoneNumber.length !== 11) {
      setPhoneNumberError("Por favor, insira um número de telefone completo.");
      hasError = true;
    } else {
      setPhoneNumberError("");
    }

    if (selectedStatuses.length === 0) {
      setStatusError("Por favor, selecione ao menos um status.");
      hasError = true;
    } else {
      setStatusError("");
    }

    if (age.length === 0) {
      setAgeError("Por favor, insira sua idade.");
      hasError = true;
    } else {
      setAgeError("");
    }

    if (age.length === 0) {
      setUsernameError("Por favor, insira seu nome.");
      hasError = true;
    } else {
      setUsernameError("");
    }

    if (impact.length === 0) {
      setImpactError("Por favor, descreva como o assédio afetou sua vida.");
      hasError = true;
    } else {
      setImpactError("");
    }

    if (hasError) {
      return;
    }

    const censoredStory = censorNames(story);
    const censoredImpact = censorNames(impact);
    const censoredSupportMessage = censorNames(supportMessage);

    const newCard = {
      username,
      phoneNumber,
      statuses: selectedStatuses.map((id) => iconDictionary[id].label),
      age,
      city,
      story: censoredStory,
      impact: censoredImpact,
      supportMessage: censoredSupportMessage,
    };

    try {
      const response = await axios.post(
        "https://back-voce-segura-production.up.railway.app/api/cards",
        newCard
      );
      onCardCreated(response.data);
      handleClose();
    } catch (error) {
      console.error("Error creating card:", error);
    }
  };

  const debouncedHandleCreateCard = useCallback(
    debounce(handleCreateCard, 300),
    [phoneNumber, selectedStatuses, age, city, story, impact, supportMessage]
  );

  // Lista de IDs dos status
  const statusList = Object.keys(iconDictionary).map((key) => parseInt(key));

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: isMobile ? 80 : 16,
          right: isMobile ? "50%" : 16,
          transform: isMobile ? "translateX(50%)" : "none",
          zIndex: 1100,
          display: "flex",
          alignItems: "center",
          gap: 1,
          backgroundColor: "primary.main",
          color: "white",
          borderRadius: 1,
          p: 1,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "primary.dark",
          },
          border: "2px solid white",
        }}
        onClick={handleOpen}
      >
        <Typography variant="body1" color="inherit">
          Insira aqui a sua história
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "inherit",
            borderRadius: "50%",
            width: 40,
            height: 40,
          }}
        >
          <AddIcon />
        </Box>
      </Box>
      <Backdrop open={open} onClick={handleClose} sx={{ zIndex: 1200 }}>
        <Box
          onClick={(e) => e.stopPropagation()}
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 400,
            maxHeight: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            zIndex: 1301,
            border: "2px solid",
            borderColor: "primary.main", // Define a cor da borda como a cor principal
            overflowY: "auto", // Enable vertical scrolling
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2" gutterBottom>
            Adicione sua história
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Agora é hora da sua história, se sinta livre para se expressar como
            se sentir melhor.
          </Typography>
          <Alert severity="warning" sx={{ mb: 2 }}>
            Pedimos que não use o nome do assediador, por questões legais.
          </Alert>
          <Typography variant="subtitle1" gutterBottom>
            Caso não saiba ou não queira citar o telefone do assediador, use por
            favor o telefone 99 999999999.
          </Typography>
          <TextField
            label="Telefone do assediador (será censurado ao enviar)"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formatPhoneNumber(phoneNumber)}
            onChange={handlePhoneNumberChange}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            error={!!phoneNumberError}
            helperText={phoneNumberError}
            InputProps={{
              startAdornment: focused && (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
          <Box sx={{ mb: 2 }} />
          {/* <Typography variant="subtitle1" gutterBottom>
            Status:
          </Typography> */}
          {statusError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {statusError}
            </Alert>
          )}
          {statusList.map((id, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={selectedStatuses.includes(id)}
                  onChange={() => handleStatusChange(id)}
                />
              }
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "primary.main",
                    color: "white",
                    p: 1,
                    borderRadius: 1,
                  }}
                >
                  {iconDictionary[id].icon}
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {iconDictionary[id].label}
                  </Typography>
                </Box>
              }
            />
          ))}
          <Box sx={{ mb: 2 }} />
          <TextField
            label="Meu nome é:"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={handleTextChange(setUsername)}
            error={!!usernameError}
            helperText={usernameError}
          />
          <TextField
            label="Minha idade é:"
            variant="outlined"
            fullWidth
            margin="normal"
            value={age}
            onChange={handleAgeChange}
            error={!!ageError}
            helperText={ageError}
          />
          <TextField
            label="Eu sou da cidade:"
            variant="outlined"
            fullWidth
            margin="normal"
            value={city}
            onChange={handleTextChange(setCity)}
          />
          <TextField
            label="Minha história de assédio é:"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            // rows={4}
            value={story}
            onChange={handleTextChange(setStory)}
          />
          <TextField
            label="Como o assédio afetou minha vida diária, relações ou saúde mental?"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            // rows={4}
            value={impact}
            onChange={handleTextChange(setImpact)}
            error={!!impactError}
            helperText={impactError}
          />
          <TextField
            label="Mensagem de apoio para outras vítimas:"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            // rows={4}
            value={supportMessage}
            onChange={handleTextChange(setSupportMessage)}
          />
          <Typography variant="subtitle1" gutterBottom>
            Ao informar sua história no site, você concorda que a mesma ficará
            disponível para ser usada, reproduzida, modificada e/ou distribuída
            em meios digitais.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={debouncedHandleCreateCard}
          >
            ENVIAR
          </Button>
        </Box>
      </Backdrop>
    </>
  );
};

export default AddCardButton;
