import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ProjectDescription from "./ProjectDescription";

// Componente NavBar que exibe a barra de navegação com um menu e título
const NavBar = () => {
  // Estado para controlar se a descrição do projeto está visível
  const [showDescription, setShowDescription] = useState(false);

  // Função para abrir a descrição do projeto
  const handleMenuClick = () => {
    setShowDescription(true);
  };

  // Função para fechar a descrição do projeto
  const handleCloseDescription = () => {
    setShowDescription(false);
  };

  return (
    <>
      {/* Barra de navegação */}
      <AppBar position="static">
        <Toolbar>
          {/* Botão de menu que abre a descrição do projeto */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton>
          {/* Título da barra de navegação */}
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: "center" }}>
            Você Segura
          </Typography>
        </Toolbar>
      </AppBar>
      {/* Componente ProjectDescription que exibe a descrição do projeto */}
      <ProjectDescription
        handleClose={handleCloseDescription}
        show={showDescription}
      />
    </>
  );
};

export default NavBar;
