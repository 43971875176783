import React, { useState } from "react";
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { formatPhoneNumber } from "../utils/formatPhoneNumber"; // Importa a função utilitária
import { useTheme } from "@mui/material/styles"; // Importa o hook useTheme

const SearchBar = ({ onSearch }) => {
  const theme = useTheme(); // Usa o hook useTheme para acessar o tema
  const [phoneNumber, setPhoneNumber] = useState("");
  const [focused, setFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
    if (numericValue.length <= 11) {
      setPhoneNumber(numericValue);
    }
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        "https://back-voce-segura-production.up.railway.app/api/cards/search/by-phone",
        {
          params: { phoneNumber: phoneNumber.replace(/\D/g, "") }, // Garante que apenas números sejam enviados
        }
      );
      const cards = response.data;
      onSearch(cards.map((card) => card.id)); // Passa os IDs dos cartões encontrados
      setErrorMessage("");
    } catch (error) {
      setErrorMessage("Número não encontrado no banco de dados");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: 2,
      }}
    >
      <TextField
        variant="outlined"
        placeholder={
          focused ? "(**) *****-****" : "Pesquise um número de celular"
        }
        sx={{
          width: "100%",
          maxWidth: 600,
          backgroundColor: theme.palette.secondary.main, // Define a cor de fundo
        }}
        value={formatPhoneNumber(phoneNumber)} // Formatar para exibição
        onChange={handleChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onKeyDown={handleKeyDown}
        InputProps={{
          startAdornment: focused && (
            <InputAdornment position="start"></InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleSearch}
                sx={{
                  backgroundColor: "primary.main",
                  color: "secondary.main",
                  "&:hover": {
                    borderWidth: 2,
                    backgroundColor: "primary.dark",
                  },
                }}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {errorMessage && (
        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default SearchBar;
