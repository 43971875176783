import React from "react";
import { Box, Typography, IconButton, Container } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Componente ProjectDescription que exibe a descrição do projeto
const ProjectDescription = ({ handleClose, show }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        bgcolor: "primary.main",
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
        zIndex: 2000,
        opacity: show ? 1 : 0,
        transition: "opacity 0.5s ease-in-out",
        pointerEvents: show ? "auto" : "none",
        overflowY: "auto", // Adiciona rolagem vertical para dispositivos móveis
      }}
    >
      {/* Botão para fechar a descrição do projeto */}
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", top: 12, left: 12, color: "white" }}
      >
        <CloseIcon />
      </IconButton>
      <Container maxWidth="md" sx={{ mt: { xs: 55, sm: 4 } }}>
        {/* Título da descrição do projeto */}
        <Typography
          variant="body1"
          sx={{ fontWeight: "bold", textAlign: "center", mb: 2 }}
        >
          Transformando Vidas e Combatendo o Assédio: Conheça Você Segura
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
          Em um mundo onde o silêncio ainda é uma resposta frequente frente ao
          assédio, surge uma necessidade urgente de dar voz às vítimas e criar
          um ambiente mais seguro para todos. É com essa motivação que nasceu
          Você Segura, uma plataforma dedicada a amplificar histórias e proteger
          futuras gerações.
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontWeight: "bold", textAlign: "center", mt: 4, mb: 2 }}
        >
          O que é o Você Segura?
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
          Você Segura é um espaço seguro e anônimo onde pessoas podem
          compartilhar suas experiências de assédio, registrando o número de
          telefone do assediador e contando a história relacionada. Nosso
          objetivo é construir uma base de dados que permita que qualquer
          pessoa, ao verificar um número de telefone, saiba se ele está
          associado a comportamentos inadequados e, assim, esteja melhor
          preparada para proteger a si mesma e a quem ama.
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontWeight: "bold", textAlign: "center", mt: 4, mb: 2 }}
        >
          Nossa Missão
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
          Buscamos não apenas relatar histórias, mas criar uma conscientização
          coletiva sobre a gravidade do assédio e o impacto que ele tem na vida
          de tantas pessoas. Acreditamos que, ao compartilhar essas histórias e
          expor comportamentos inapropriados, podemos evitar que novos casos
          aconteçam e inspirar uma mudança cultural que valorize o respeito e a
          empatia.
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontWeight: "bold", textAlign: "center", mt: 4, mb: 2 }}
        >
          Como Pretendemos Mudar o Mundo para Melhor
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
          Nosso propósito é construir uma rede de apoio e prevenção. Queremos
          que, através do compartilhamento de experiências, mais pessoas se
          sintam encorajadas a falar, a se defender e a proteger umas às outras.
          Ao democratizar o acesso a essas informações, contribuímos para uma
          sociedade mais consciente e atenta, onde o assédio é cada vez menos
          tolerado.
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
          Junte-se a nós nesta jornada por um mundo mais seguro, onde cada
          relato é um passo em direção à justiça e ao respeito. Que o Você
          Segura seja uma fonte de força, união e esperança para todos que
          desejam viver sem medo.
        </Typography>
      </Container>
    </Box>
  );
};

export default ProjectDescription;
