import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, Backdrop } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import iconDictionary from "./iconDictionary";
import CommentForm from "./CommentForm";
import axios from "axios";

// Componente OpenCard que exibe um card aberto com informações detalhadas
const OpenCard = ({ cardId, handleClose }) => {
  const [censoredPhoneNumber, setCensoredPhoneNumber] = useState("");
  const [username, setUsername] = useState("");
  const [age, setAge] = useState("");
  const [city, setCity] = useState("");
  const [story, setStory] = useState("");
  const [impact, setImpact] = useState("");
  const [supportMessage, setSupportMessage] = useState("");
  const [iconList, setIconList] = useState([]);
  const [comments, setComments] = useState([]);

  // Função para adicionar um novo comentário à lista
  const handleCommentAdded = (newComment) => {
    setComments((prevComments) => [...(prevComments || []), newComment]);
  };

  // Função para buscar os dados do card pelo ID
  const fetchCardData = async (id) => {
    try {
      const response = await axios.get(
        `https://back-voce-segura-production.up.railway.app/api/cards/${id}`
      );
      const card = response.data;
      setCensoredPhoneNumber(card.censoredphonenumber);
      setUsername(card.username);
      setAge(card.age);
      setCity(card.city);
      setStory(card.story);
      setImpact(card.impact);
      setSupportMessage(card.supportmessage);
      setIconList(
        card.statuses.map((status) => {
          const iconId = Object.keys(iconDictionary).find(
            (key) => iconDictionary[key].label === status
          );
          return parseInt(iconId);
        })
      );
      setComments(card.comments);
    } catch (error) {
      console.error("Error fetching card data:", error);
    }
  };

  useEffect(() => {
    if (cardId) {
      fetchCardData(cardId);
    }
  }, [cardId]);

  return (
    <Backdrop open={!!cardId} onClick={handleClose} sx={{ zIndex: 1200 }}>
      <Box
        onClick={(e) => e.stopPropagation()}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 400,
          maxHeight: "90%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          zIndex: 1301,
          border: "2px solid",
          borderColor: "primary.main",
          overflowY: "auto",
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2" gutterBottom>
          {censoredPhoneNumber}
        </Typography>
        {/* Ícones associados ao card */}
        <Box mt={2}>
          {iconList.map((id, index) => {
            const iconData = iconDictionary[id];
            if (!iconData) {
              console.error(`Icon with id ${id} not found in iconDictionary`);
              return null;
            }
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                  backgroundColor: "primary.main",
                  color: "white",
                  p: 1,
                  borderRadius: 1,
                }}
              >
                {iconData.icon}
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {iconData.label}
                </Typography>
              </Box>
            );
          })}
        </Box>
        {/* Nome */}
        <Box
          sx={{
            border: "1px solid",
            borderColor: "secondary.main",
            p: 2,
            mb: 2,
            borderRadius: 1,
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Nome:
          </Typography>
          <Typography variant="body2">- {username}</Typography>
        </Box>
        {/* Idade */}
        <Box
          sx={{
            border: "1px solid",
            borderColor: "secondary.main",
            p: 2,
            mb: 2,
            borderRadius: 1,
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Idade:
          </Typography>
          <Typography variant="body2">- {age}</Typography>
        </Box>
        {/* Cidade */}
        <Box
          sx={{
            border: "1px solid",
            borderColor: "secondary.main",
            p: 2,
            mb: 2,
            borderRadius: 1,
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Cidade:
          </Typography>
          <Typography variant="body2">- {city}</Typography>
        </Box>
        {/* Minha história */}
        <Box
          sx={{
            border: "1px solid",
            borderColor: "secondary.main",
            p: 2,
            mb: 2,
            borderRadius: 1,
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Minha história:
          </Typography>
          <Typography variant="body2">- {story}</Typography>
        </Box>
        {/* Impacto */}
        <Box
          sx={{
            border: "1px solid",
            borderColor: "secondary.main",
            p: 2,
            mb: 2,
            borderRadius: 1,
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Como me senti:
          </Typography>
          <Typography variant="body2">- {impact}</Typography>
        </Box>
        {/* Mensagem de apoio */}
        <Box
          sx={{
            border: "1px solid",
            borderColor: "secondary.main",
            p: 2,
            mb: 2,
            borderRadius: 1,
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Mensagem de apoio:
          </Typography>
          <Typography variant="body2">- {supportMessage}</Typography>
        </Box>
        {/* Comentários */}
        <Box mt={2}>
          <Typography variant="subtitle1">Comentários:</Typography>
          {comments.map((comment, index) => (
            <Typography
              key={index}
              variant="body2"
              sx={{
                mt: 1,
                wordWrap: "break-word",
                maxWidth: "90%",
              }}
            >
              - {comment.comment}
            </Typography>
          ))}
        </Box>
        {/* Campo para adicionar um novo comentário */}
        <CommentForm cardId={cardId} onCommentAdded={handleCommentAdded} />
      </Box>
    </Backdrop>
  );
};

export default OpenCard;
