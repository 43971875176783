import React, { useState } from "react";
import { Box, TextField, Button } from "@mui/material";
import axios from "axios";
import names from "../utils/names.json"; // Importa a lista de nomes

// Componente CommentForm que permite adicionar um novo comentário a um card
const CommentForm = ({ cardId, onCommentAdded }) => {
  const [comment, setComment] = useState("");

  // Função para lidar com a mudança no campo de comentário
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  // Função para censurar nomes, ignorando a capitalização
  const censorNames = (text) => {
    const regex = new RegExp(`\\b(${names.join("|")})\\b`, "gi");
    return text.replace(regex, (match) => "*".repeat(match.length));
  };

  // Função para enviar o comentário
  const handleAddComment = async () => {
    if (comment.trim()) {
      // Censurar nomes no comentário
      const censoredComment = censorNames(comment);

      try {
        const response = await axios.post(
          `https://back-voce-segura-production.up.railway.app/api/cards/${cardId}/comments`,
          { comment: censoredComment }
        );
        onCommentAdded(response.data);
        setComment("");
      } catch (error) {
        console.error("Error adding comment:", error);
      }
    }
  };

  return (
    <Box mt={2}>
      <TextField
        label="Adicionar Comentário"
        variant="outlined"
        fullWidth
        value={comment}
        onChange={handleCommentChange}
        multiline
        rows={2}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 1 }}
        onClick={handleAddComment}
      >
        Enviar Comentário
      </Button>
    </Box>
  );
};

export default CommentForm;
