import { Box, Button, Badge, Typography } from "@mui/material";
import iconDictionary from "./iconDictionary";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Componente FilterButtons que exibe uma lista de botões de filtro com ícones
const FilterButtons = ({
  selectedFilters,
  setSelectedFilters,
  filteredCards = [],
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Lista de IDs dos filtros
  const filterIds = [1, 2, 3, 4];

  // Função para lidar com o clique no botão de filtro
  const handleFilterClick = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((filterId) => filterId !== id));
    } else {
      setSelectedFilters([...selectedFilters, id]);
    }
  };

  // Calcular o número de cards para cada filtro
  const getCardCountForFilter = (id) => {
    const label = iconDictionary[id].label;
    return filteredCards.filter((card) => card.statuses.includes(label)).length;
  };

  // Encontrar o maior texto para definir o tamanho dos botões no mobile
  const maxLabelLength = Math.max(
    ...filterIds.map((id) => iconDictionary[id].label.length)
  );
  const buttonWidth = isMobile ? `${maxLabelLength + 4}ch` : "auto"; // Adiciona um pouco de espaço extra

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "center",
        alignItems: isMobile ? "center" : "flex-start", // Centraliza os botões horizontalmente no modo mobile
        gap: 2,
        marginBottom: 2,
      }}
    >
      {filterIds.map((id) => (
        <Box
          key={id}
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: buttonWidth, // Define a largura dos botões no mobile
          }}
        >
          <Badge
            badgeContent={
              <Typography
                variant="caption"
                sx={{ color: theme.palette.secondary.main }}
              >
                {getCardCountForFilter(id)}
              </Typography>
            }
            color="primary"
            sx={{
              position: "absolute",
              top: -10,
              right: -10,
              zIndex: 1,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.secondary.main,
              borderRadius: "50%",
            }}
          />
          <Button
            variant="contained"
            color={selectedFilters.includes(id) ? "secondary" : "primary"}
            startIcon={iconDictionary[id].icon}
            onClick={() => handleFilterClick(id)}
            sx={{
              width: "100%", // Garante que o botão ocupe toda a largura do Box
              "&:hover": {
                backgroundColor: selectedFilters.includes(id)
                  ? "secondary.dark"
                  : "primary.dark",
              },
            }}
          >
            {iconDictionary[id].label}
          </Button>
        </Box>
      ))}
    </Box>
  );
};

export default FilterButtons;
