import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Divider,
  Box,
  Grid,
  Button,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import { FaInstagram, FaTiktok } from "react-icons/fa";
import NavBar from "./components/NavBar";
import SearchBar from "./components/SearchBar";
import FilterButtons from "./components/FilterButtons";
import CardComponent from "./components/CardComponent";
import AddCardButton from "./components/AddCardButton";
import OpenCard from "./components/OpenCard";
import iconDictionary from "./components/iconDictionary";
import axios from "axios";
import topo1 from "./assets/img/topo-1.png";
import topo2 from "./assets/img/topo-2.png";
import topo3 from "./assets/img/topo-3.png";
import pharmacy from "./assets/gif/pharmacy.gif";
import takeOut from "./assets/gif/take-out.gif";
import convenience from "./assets/gif/convenience.gif";

const App = () => {
  const [cards, setCards] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [visibleCards, setVisibleCards] = useState(30); // Estado para controlar a quantidade de cards visíveis
  const [openCardId, setOpenCardId] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await axios.get(
          "https://back-voce-segura-production.up.railway.app/api/cards"
        );
        setCards(response.data.reverse()); // Reverter a ordem dos cards ao buscar
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    };
    fetchCards();
  }, []);

  const handleCardCreated = (newCard) => {
    setCards((prevCards) => [newCard, ...prevCards]);
    setSearchResults([]); // Limpa os resultados da pesquisa após a adição de um novo card
  };

  const handleSearch = async (cardIds) => {
    const results = cards.filter((card) => cardIds.includes(card.id));
    setSearchResults(results.length > 0 ? results : []);
    return results.length > 0 ? results : null;
  };

  const filteredCards = selectedFilters.length
    ? (searchResults.length ? searchResults : cards).filter((card) =>
        card.statuses.some((status) =>
          selectedFilters.includes(
            parseInt(
              Object.keys(iconDictionary).find(
                (key) => iconDictionary[key].label === status
              )
            )
          )
        )
      )
    : searchResults.length
    ? searchResults
    : cards;

  const handleLoadMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 30);
  };

  const handleCardClick = async (cardId) => {
    setOpenCardId(cardId);
  };

  const getColumnCards = (columnIndex, totalColumns) => {
    return filteredCards
      .filter((_, index) => index % totalColumns === columnIndex)
      .map((card) => (
        <CardComponent
          key={card.id}
          cardId={card.id}
          censoredPhoneNumber={card.censoredphonenumber}
          description={card.description}
          iconList={card.statuses.map((status) =>
            parseInt(
              Object.keys(iconDictionary).find(
                (key) => iconDictionary[key].label === status
              )
            )
          )}
          initialComments={card.comments}
          sx={{ backgroundColor: "#0b1929" }} // Adiciona o background color aos cards
          onClick={() => handleCardClick(card.id)}
        />
      ));
  };

  const topoStyle = {
    width: "calc(100% + 20px)", // Aumenta a largura em 20px
    marginLeft: "-10px", // Move o elemento 10px para a esquerda
  };

  return (
    <div className="App">
      <div id="clouds"></div> {/* Adicione este elemento */}
      <NavBar />
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Typography variant="body1" align="center" color="secondary.main">
          O "Você Segura" é uma plataforma de denúncias anônimas, projetada para
          permitir que pessoas de todas as partes da sociedade possam reportar,
          de forma segura e confidencial, práticas abusivas e comportamentos
          inadequados.
        </Typography>
        <Divider sx={{ my: 2, border: "none" }} /> {/* Divider sem aparência */}
        <SearchBar onSearch={handleSearch} />
        <Divider sx={{ my: 2, border: "none" }} /> {/* Divider sem aparência */}
        <FilterButtons
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          filteredCards={filteredCards} // Passe filteredCards como prop
        />
        <Divider sx={{ my: 2, border: "none" }} /> {/* Divider sem aparência */}
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {isMobile ? (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <img src={topo1} alt="Topo 1" style={{ width: "100%" }} />
                <Box sx={{ flexGrow: 1, backgroundColor: "#0b1929" }}>
                  {filteredCards.map((card) => (
                    <CardComponent
                      key={card.id}
                      cardId={card.id}
                      censoredPhoneNumber={card.censoredphonenumber}
                      description={card.description}
                      iconList={card.statuses.map((status) =>
                        parseInt(
                          Object.keys(iconDictionary).find(
                            (key) => iconDictionary[key].label === status
                          )
                        )
                      )}
                      initialComments={card.comments}
                      sx={{ backgroundColor: "#0b1929" }} // Adiciona o background color aos cards
                      onClick={() => handleCardClick(card.id)}
                    />
                  ))}
                </Box>
                <img src={pharmacy} alt="Pharmacy" style={{ width: "100%" }} />
              </Box>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <img src={topo1} alt="Topo 1" style={topoStyle} />
                  <Box sx={{ flexGrow: 1, backgroundColor: "#0b1929" }}>
                    {getColumnCards(0, 3)}
                  </Box>
                  <img
                    src={pharmacy}
                    alt="Pharmacy"
                    style={{ width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <img src={topo2} alt="Topo 2" style={topoStyle} />
                  <Box sx={{ flexGrow: 1, backgroundColor: "#0b1929" }}>
                    {getColumnCards(1, 3)}
                  </Box>
                  <img src={takeOut} alt="Take Out" style={{ width: "100%" }} />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <img src={topo3} alt="Topo 3" style={topoStyle} />
                  <Box sx={{ flexGrow: 1, backgroundColor: "#0b1929" }}>
                    {getColumnCards(2, 3)}
                  </Box>
                  <img
                    src={convenience}
                    alt="Convenience"
                    style={{ width: "100%" }}
                  />
                </Box>
              </Grid>
            </>
          )}
        </Grid>
        {visibleCards < filteredCards.length && (
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <Button onClick={handleLoadMore} color="secondary.main">
              Carregar Mais
            </Button>
          </Box>
        )}
      </Container>
      <Box
        sx={{
          position: "fixed",
          bottom: 80, // Adjust this value as needed to position above the footer
          right: 16,
          zIndex: 1100,
        }}
      >
        <AddCardButton onCardCreated={handleCardCreated} />
      </Box>
      <Box
        component="footer"
        sx={{
          mt: 4,
          py: 2,
          backgroundColor: "primary.main",
          color: "white",
          textAlign: "center",
        }}
      >
        <Typography variant="body2">
          © 2024 Você Segura. Todos os direitos reservados.
        </Typography>
        <Typography variant="body2">Email: contato@vocesegura.com</Typography>
        <Box sx={{ mt: 2 }}>
          <IconButton
            component="a"
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
          >
            <FaInstagram />
          </IconButton>
          <IconButton
            component="a"
            href="https://www.tiktok.com"
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
          >
            <FaTiktok />
          </IconButton>
        </Box>
      </Box>
      {openCardId && (
        <OpenCard cardId={openCardId} handleClose={() => setOpenCardId(null)} />
      )}
    </div>
  );
};

export default App;
