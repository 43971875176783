import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import storey1 from "../assets/img/apartments/storey_1.png";
import storey2 from "../assets/img/apartments/storey_2.png";
import storey3 from "../assets/img/apartments/storey_3.png";
import storey4 from "../assets/img/apartments/storey_4.png";
import storey5 from "../assets/img/apartments/storey_5.png";
import storey6 from "../assets/img/apartments/storey_6.png";
import storey7 from "../assets/img/apartments/storey_7.png";
import storey8 from "../assets/img/apartments/storey_8.png";
import storey9 from "../assets/img/apartments/storey_9.png";
import storey10 from "../assets/img/apartments/storey_10.png";
import storey11 from "../assets/img/apartments/storey_11.png";
import storey12 from "../assets/img/apartments/storey_12.png";
import storey13 from "../assets/img/apartments/storey_13.png";
import storey14 from "../assets/img/apartments/storey_14.png";
import storey15 from "../assets/img/apartments/storey_15.png";
import storey16 from "../assets/img/apartments/storey_16.png";
import storey17 from "../assets/img/apartments/storey_17.png";
import storey18 from "../assets/img/apartments/storey_18.png";
import storey19 from "../assets/img/apartments/storey_19.png";
import storey20 from "../assets/img/apartments/storey_20.png";
import storey21 from "../assets/img/apartments/storey_21.png";
import storey22 from "../assets/img/apartments/storey_22.png";
import storey23 from "../assets/img/apartments/storey_23.png";
import storey24 from "../assets/img/apartments/storey_24.png";
import storey25 from "../assets/img/apartments/storey_25.png";
import storey26 from "../assets/img/apartments/storey_26.png";
import storey27 from "../assets/img/apartments/storey_27.png";
import storey28 from "../assets/img/apartments/storey_28.png";
import storey29 from "../assets/img/apartments/storey_29.png";
import storey30 from "../assets/img/apartments/storey_30.png";
import storey31 from "../assets/img/apartments/storey_31.png";
import storey32 from "../assets/img/apartments/storey_32.png";
import storey33 from "../assets/img/apartments/storey_33.png";
import storey34 from "../assets/img/apartments/storey_34.png";
import storey35 from "../assets/img/apartments/storey_35.png";
import storey36 from "../assets/img/apartments/storey_36.png";
import storey37 from "../assets/img/apartments/storey_37.png";
import storey38 from "../assets/img/apartments/storey_38.png";
import storey39 from "../assets/img/apartments/storey_39.png";
import storey40 from "../assets/img/apartments/storey_40.png";
import storey41 from "../assets/img/apartments/storey_41.png";
import storey42 from "../assets/img/apartments/storey_42.png";
import storey43 from "../assets/img/apartments/storey_43.png";
import storey44 from "../assets/img/apartments/storey_44.png";
import storey45 from "../assets/img/apartments/storey_45.png";
import storey46 from "../assets/img/apartments/storey_46.png";
import storey47 from "../assets/img/apartments/storey_47.png";
import storey48 from "../assets/img/apartments/storey_48.png";
import storey49 from "../assets/img/apartments/storey_49.png";
import storey50 from "../assets/img/apartments/storey_50.png";
import storey51 from "../assets/img/apartments/storey_51.png";
import storey52 from "../assets/img/apartments/storey_52.png";
import storey53 from "../assets/img/apartments/storey_53.png";
import storey54 from "../assets/img/apartments/storey_54.png";
import storey55 from "../assets/img/apartments/storey_55.png";
import storey56 from "../assets/img/apartments/storey_56.png";
import storey57 from "../assets/img/apartments/storey_57.png";
import storey58 from "../assets/img/apartments/storey_58.png";
import storey59 from "../assets/img/apartments/storey_59.png";
import storey60 from "../assets/img/apartments/storey_60.png";
import storey61 from "../assets/img/apartments/storey_61.png";
import storey62 from "../assets/img/apartments/storey_62.png";
import storey63 from "../assets/img/apartments/storey_63.png";
import storey64 from "../assets/img/apartments/storey_64.png";
import storey65 from "../assets/img/apartments/storey_65.png";
import storey66 from "../assets/img/apartments/storey_66.png";
import storey67 from "../assets/img/apartments/storey_67.png";
import storey68 from "../assets/img/apartments/storey_68.png";
import storey69 from "../assets/img/apartments/storey_69.png";
import storey70 from "../assets/img/apartments/storey_70.png";
import storey71 from "../assets/img/apartments/storey_71.png";
import storey72 from "../assets/img/apartments/storey_72.png";
import storey73 from "../assets/img/apartments/storey_73.png";
import storey74 from "../assets/img/apartments/storey_74.png";
import storey75 from "../assets/img/apartments/storey_75.png";
import storey76 from "../assets/img/apartments/storey_76.png";
import storey77 from "../assets/img/apartments/storey_77.png";
import storey78 from "../assets/img/apartments/storey_78.png";
import storey79 from "../assets/img/apartments/storey_79.png";
import storey80 from "../assets/img/apartments/storey_80.png";
import storey81 from "../assets/img/apartments/storey_81.png";
import storey82 from "../assets/img/apartments/storey_82.png";
import storey83 from "../assets/img/apartments/storey_83.png";
import storey84 from "../assets/img/apartments/storey_84.png";
import storey85 from "../assets/img/apartments/storey_85.png";
import storey86 from "../assets/img/apartments/storey_86.png";
import storey87 from "../assets/img/apartments/storey_87.png";
import storey88 from "../assets/img/apartments/storey_88.png";
import storey89 from "../assets/img/apartments/storey_89.png";
import storey90 from "../assets/img/apartments/storey_90.png";
import storey91 from "../assets/img/apartments/storey_91.png";
import storey92 from "../assets/img/apartments/storey_92.png";
import storey93 from "../assets/img/apartments/storey_93.png";
import storey94 from "../assets/img/apartments/storey_94.png";
import storey95 from "../assets/img/apartments/storey_95.png";
import storey96 from "../assets/img/apartments/storey_96.png";
import storey97 from "../assets/img/apartments/storey_97.png";
import storey98 from "../assets/img/apartments/storey_98.png";
import storey99 from "../assets/img/apartments/storey_99.png";
import storey100 from "../assets/img/apartments/storey_100.png";
import storey101 from "../assets/img/apartments/storey_101.png";
import storey102 from "../assets/img/apartments/storey_102.png";
import storey103 from "../assets/img/apartments/storey_103.png";
import storey104 from "../assets/img/apartments/storey_104.png";
import storey105 from "../assets/img/apartments/storey_105.png";
import storey106 from "../assets/img/apartments/storey_106.png";
import storey107 from "../assets/img/apartments/storey_107.png";
import storey108 from "../assets/img/apartments/storey_108.png";
import storey109 from "../assets/img/apartments/storey_109.png";
import storey110 from "../assets/img/apartments/storey_110.png";
import storey111 from "../assets/img/apartments/storey_111.png";
import storey112 from "../assets/img/apartments/storey_112.png";
import storey113 from "../assets/img/apartments/storey_113.png";
import storey114 from "../assets/img/apartments/storey_114.png";
import storey115 from "../assets/img/apartments/storey_115.png";
import storey116 from "../assets/img/apartments/storey_116.png";
import storey117 from "../assets/img/apartments/storey_117.png";
import storey118 from "../assets/img/apartments/storey_118.png";
import storey119 from "../assets/img/apartments/storey_119.png";
import storey120 from "../assets/img/apartments/storey_120.png";
import storey121 from "../assets/img/apartments/storey_121.png";
import storey122 from "../assets/img/apartments/storey_122.png";
import storey123 from "../assets/img/apartments/storey_123.png";
import storey124 from "../assets/img/apartments/storey_124.png";
import storey125 from "../assets/img/apartments/storey_125.png";
import storey126 from "../assets/img/apartments/storey_126.png";
import storey127 from "../assets/img/apartments/storey_127.png";
import storey128 from "../assets/img/apartments/storey_128.png";
import storey129 from "../assets/img/apartments/storey_129.png";
import storey130 from "../assets/img/apartments/storey_130.png";
import storey131 from "../assets/img/apartments/storey_131.png";
import storey132 from "../assets/img/apartments/storey_132.png";
import storey133 from "../assets/img/apartments/storey_133.png";
import storey134 from "../assets/img/apartments/storey_134.png";
import storey135 from "../assets/img/apartments/storey_135.png";
import storey136 from "../assets/img/apartments/storey_136.png";
import storey137 from "../assets/img/apartments/storey_137.png";
import storey138 from "../assets/img/apartments/storey_138.png";
import storey139 from "../assets/img/apartments/storey_139.png";
import storey140 from "../assets/img/apartments/storey_140.png";
import storey141 from "../assets/img/apartments/storey_141.png";
import storey142 from "../assets/img/apartments/storey_142.png";
import storey143 from "../assets/img/apartments/storey_143.png";
import storey144 from "../assets/img/apartments/storey_144.png";
import storey145 from "../assets/img/apartments/storey_145.png";
import storey146 from "../assets/img/apartments/storey_146.png";
import storey147 from "../assets/img/apartments/storey_147.png";
import storey148 from "../assets/img/apartments/storey_148.png";
import storey149 from "../assets/img/apartments/storey_149.png";
import storey150 from "../assets/img/apartments/storey_150.png";
import storey151 from "../assets/img/apartments/storey_151.png";
import storey152 from "../assets/img/apartments/storey_152.png";
import storey153 from "../assets/img/apartments/storey_153.png";
import storey154 from "../assets/img/apartments/storey_154.png";
import storey155 from "../assets/img/apartments/storey_155.png";
import storey156 from "../assets/img/apartments/storey_156.png";
import storey157 from "../assets/img/apartments/storey_157.png";
import storey158 from "../assets/img/apartments/storey_158.png";
import storey159 from "../assets/img/apartments/storey_159.png";
import storey160 from "../assets/img/apartments/storey_160.png";
import storey161 from "../assets/img/apartments/storey_161.png";
import storey162 from "../assets/img/apartments/storey_162.png";
import storey163 from "../assets/img/apartments/storey_163.png";
import storey164 from "../assets/img/apartments/storey_164.png";
import storey165 from "../assets/img/apartments/storey_165.png";
import storey166 from "../assets/img/apartments/storey_166.png";
import storey167 from "../assets/img/apartments/storey_167.png";
import storey168 from "../assets/img/apartments/storey_168.png";
import storey169 from "../assets/img/apartments/storey_169.png";
import storey170 from "../assets/img/apartments/storey_170.png";
import storey171 from "../assets/img/apartments/storey_171.png";
import storey172 from "../assets/img/apartments/storey_172.png";
import storey173 from "../assets/img/apartments/storey_173.png";
import storey174 from "../assets/img/apartments/storey_174.png";
import storey175 from "../assets/img/apartments/storey_175.png";
import storey176 from "../assets/img/apartments/storey_176.png";
import storey177 from "../assets/img/apartments/storey_177.png";
import storey178 from "../assets/img/apartments/storey_178.png";
import storey179 from "../assets/img/apartments/storey_179.png";
import storey180 from "../assets/img/apartments/storey_180.png";
import storey181 from "../assets/img/apartments/storey_181.png";
import storey182 from "../assets/img/apartments/storey_182.png";
import storey183 from "../assets/img/apartments/storey_183.png";
import storey184 from "../assets/img/apartments/storey_184.png";
import storey185 from "../assets/img/apartments/storey_185.png";
import storey186 from "../assets/img/apartments/storey_186.png";
import storey187 from "../assets/img/apartments/storey_187.png";
import storey188 from "../assets/img/apartments/storey_188.png";
import storey189 from "../assets/img/apartments/storey_189.png";
import storey190 from "../assets/img/apartments/storey_190.png";
import storey191 from "../assets/img/apartments/storey_191.png";
import storey192 from "../assets/img/apartments/storey_192.png";
import storey193 from "../assets/img/apartments/storey_193.png";
import storey194 from "../assets/img/apartments/storey_194.png";
import storey195 from "../assets/img/apartments/storey_195.png";
import storey196 from "../assets/img/apartments/storey_196.png";
import storey197 from "../assets/img/apartments/storey_197.png";
import storey198 from "../assets/img/apartments/storey_198.png";
import storey199 from "../assets/img/apartments/storey_199.png";
import storey200 from "../assets/img/apartments/storey_200.png";
import storey201 from "../assets/img/apartments/storey_201.png";
import storey202 from "../assets/img/apartments/storey_202.png";
import storey203 from "../assets/img/apartments/storey_203.png";
import storey204 from "../assets/img/apartments/storey_204.png";
import storey205 from "../assets/img/apartments/storey_205.png";
import storey206 from "../assets/img/apartments/storey_206.png";
import storey207 from "../assets/img/apartments/storey_207.png";
import storey208 from "../assets/img/apartments/storey_208.png";
import storey209 from "../assets/img/apartments/storey_209.png";
import storey210 from "../assets/img/apartments/storey_210.png";
import storey211 from "../assets/img/apartments/storey_211.png";
import storey212 from "../assets/img/apartments/storey_212.png";
import storey213 from "../assets/img/apartments/storey_213.png";
import storey214 from "../assets/img/apartments/storey_214.png";
import storey215 from "../assets/img/apartments/storey_215.png";
import storey216 from "../assets/img/apartments/storey_216.png";
import storey217 from "../assets/img/apartments/storey_217.png";
import storey218 from "../assets/img/apartments/storey_218.png";
import storey219 from "../assets/img/apartments/storey_219.png";
import storey220 from "../assets/img/apartments/storey_220.png";
import storey221 from "../assets/img/apartments/storey_221.png";
import storey222 from "../assets/img/apartments/storey_222.png";
import storey223 from "../assets/img/apartments/storey_223.png";
import storey224 from "../assets/img/apartments/storey_224.png";
import storey225 from "../assets/img/apartments/storey_225.png";
import storey226 from "../assets/img/apartments/storey_226.png";
import storey227 from "../assets/img/apartments/storey_227.png";
import storey228 from "../assets/img/apartments/storey_228.png";
import storey229 from "../assets/img/apartments/storey_229.png";
import storey230 from "../assets/img/apartments/storey_230.png";
import storey231 from "../assets/img/apartments/storey_231.png";
import storey232 from "../assets/img/apartments/storey_232.png";
import storey233 from "../assets/img/apartments/storey_233.png";
import storey234 from "../assets/img/apartments/storey_234.png";
import storey235 from "../assets/img/apartments/storey_235.png";
import storey236 from "../assets/img/apartments/storey_236.png";
import storey237 from "../assets/img/apartments/storey_237.png";
import storey238 from "../assets/img/apartments/storey_238.png";
import storey239 from "../assets/img/apartments/storey_239.png";
import storey240 from "../assets/img/apartments/storey_240.png";
import storey241 from "../assets/img/apartments/storey_241.png";
import storey242 from "../assets/img/apartments/storey_242.png";
import storey243 from "../assets/img/apartments/storey_243.png";
import storey244 from "../assets/img/apartments/storey_244.png";
import storey245 from "../assets/img/apartments/storey_245.png";
import storey246 from "../assets/img/apartments/storey_246.png";
import storey247 from "../assets/img/apartments/storey_247.png";
import storey248 from "../assets/img/apartments/storey_248.png";
import storey249 from "../assets/img/apartments/storey_249.png";
import storey250 from "../assets/img/apartments/storey_250.png";
import storey251 from "../assets/img/apartments/storey_251.png";
import storey252 from "../assets/img/apartments/storey_252.png";
import storey253 from "../assets/img/apartments/storey_253.png";
import storey254 from "../assets/img/apartments/storey_254.png";
import storey255 from "../assets/img/apartments/storey_255.png";
import storey256 from "../assets/img/apartments/storey_256.png";
import storey257 from "../assets/img/apartments/storey_257.png";
import storey258 from "../assets/img/apartments/storey_258.png";
import storey259 from "../assets/img/apartments/storey_259.png";
import storey260 from "../assets/img/apartments/storey_260.png";
import storey261 from "../assets/img/apartments/storey_261.png";
import storey262 from "../assets/img/apartments/storey_262.png";
import storey263 from "../assets/img/apartments/storey_263.png";
import storey264 from "../assets/img/apartments/storey_264.png";
import storey265 from "../assets/img/apartments/storey_265.png";
import storey266 from "../assets/img/apartments/storey_266.png";
import storey267 from "../assets/img/apartments/storey_267.png";
import storey268 from "../assets/img/apartments/storey_268.png";
import storey269 from "../assets/img/apartments/storey_269.png";
import storey270 from "../assets/img/apartments/storey_270.png";
import storey271 from "../assets/img/apartments/storey_271.png";
import storey272 from "../assets/img/apartments/storey_272.png";
import storey273 from "../assets/img/apartments/storey_273.png";
import storey274 from "../assets/img/apartments/storey_274.png";
import storey275 from "../assets/img/apartments/storey_275.png";
import storey276 from "../assets/img/apartments/storey_276.png";
import storey277 from "../assets/img/apartments/storey_277.png";
import storey278 from "../assets/img/apartments/storey_278.png";
import storey279 from "../assets/img/apartments/storey_279.png";
import storey280 from "../assets/img/apartments/storey_280.png";
import storey281 from "../assets/img/apartments/storey_281.png";
import storey282 from "../assets/img/apartments/storey_282.png";
import storey283 from "../assets/img/apartments/storey_283.png";
import storey284 from "../assets/img/apartments/storey_284.png";
import storey285 from "../assets/img/apartments/storey_285.png";
import storey286 from "../assets/img/apartments/storey_286.png";
import storey287 from "../assets/img/apartments/storey_287.png";
import storey288 from "../assets/img/apartments/storey_288.png";
import storey289 from "../assets/img/apartments/storey_289.png";
import storey290 from "../assets/img/apartments/storey_290.png";
import storey291 from "../assets/img/apartments/storey_291.png";
import storey292 from "../assets/img/apartments/storey_292.png";
import storey293 from "../assets/img/apartments/storey_293.png";
import storey294 from "../assets/img/apartments/storey_294.png";
import storey295 from "../assets/img/apartments/storey_295.png";
import storey296 from "../assets/img/apartments/storey_296.png";
import storey297 from "../assets/img/apartments/storey_297.png";
import storey298 from "../assets/img/apartments/storey_298.png";
import storey299 from "../assets/img/apartments/storey_299.png";
import storey300 from "../assets/img/apartments/storey_300.png";
const imageDictionary = {
  1: storey1,
  2: storey2,
  3: storey3,
  4: storey4,
  5: storey5,
  6: storey6,
  7: storey7,
  8: storey8,
  9: storey9,
  10: storey10,
  11: storey11,
  12: storey12,
  13: storey13,
  14: storey14,
  15: storey15,
  16: storey16,
  17: storey17,
  18: storey18,
  19: storey19,
  20: storey20,
  21: storey21,
  22: storey22,
  23: storey23,
  24: storey24,
  25: storey25,
  26: storey26,
  27: storey27,
  28: storey28,
  29: storey29,
  30: storey30,
  31: storey31,
  32: storey32,
  33: storey33,
  34: storey34,
  35: storey35,
  36: storey36,
  37: storey37,
  38: storey38,
  39: storey39,
  40: storey40,
  41: storey41,
  42: storey42,
  43: storey43,
  44: storey44,
  45: storey45,
  46: storey46,
  47: storey47,
  48: storey48,
  49: storey49,
  50: storey50,
  51: storey51,
  52: storey52,
  53: storey53,
  54: storey54,
  55: storey55,
  56: storey56,
  57: storey57,
  58: storey58,
  59: storey59,
  60: storey60,
  61: storey61,
  62: storey62,
  63: storey63,
  64: storey64,
  65: storey65,
  66: storey66,
  67: storey67,
  68: storey68,
  69: storey69,
  70: storey70,
  71: storey71,
  72: storey72,
  73: storey73,
  74: storey74,
  75: storey75,
  76: storey76,
  77: storey77,
  78: storey78,
  79: storey79,
  80: storey80,
  81: storey81,
  82: storey82,
  83: storey83,
  84: storey84,
  85: storey85,
  86: storey86,
  87: storey87,
  88: storey88,
  89: storey89,
  90: storey90,
  91: storey91,
  92: storey92,
  93: storey93,
  94: storey94,
  95: storey95,
  96: storey96,
  97: storey97,
  98: storey98,
  99: storey99,
  100: storey100,
  101: storey101,
  102: storey102,
  103: storey103,
  104: storey104,
  105: storey105,
  106: storey106,
  107: storey107,
  108: storey108,
  109: storey109,
  110: storey110,
  111: storey111,
  112: storey112,
  113: storey113,
  114: storey114,
  115: storey115,
  116: storey116,
  117: storey117,
  118: storey118,
  119: storey119,
  120: storey120,
  121: storey121,
  122: storey122,
  123: storey123,
  124: storey124,
  125: storey125,
  126: storey126,
  127: storey127,
  128: storey128,
  129: storey129,
  130: storey130,
  131: storey131,
  132: storey132,
  133: storey133,
  134: storey134,
  135: storey135,
  136: storey136,
  137: storey137,
  138: storey138,
  139: storey139,
  140: storey140,
  141: storey141,
  142: storey142,
  143: storey143,
  144: storey144,
  145: storey145,
  146: storey146,
  147: storey147,
  148: storey148,
  149: storey149,
  150: storey150,
  151: storey151,
  152: storey152,
  153: storey153,
  154: storey154,
  155: storey155,
  156: storey156,
  157: storey157,
  158: storey158,
  159: storey159,
  160: storey160,
  161: storey161,
  162: storey162,
  163: storey163,
  164: storey164,
  165: storey165,
  166: storey166,
  167: storey167,
  168: storey168,
  169: storey169,
  170: storey170,
  171: storey171,
  172: storey172,
  173: storey173,
  174: storey174,
  175: storey175,
  176: storey176,
  177: storey177,
  178: storey178,
  179: storey179,
  180: storey180,
  181: storey181,
  182: storey182,
  183: storey183,
  184: storey184,
  185: storey185,
  186: storey186,
  187: storey187,
  188: storey188,
  189: storey189,
  190: storey190,
  191: storey191,
  192: storey192,
  193: storey193,
  194: storey194,
  195: storey195,
  196: storey196,
  197: storey197,
  198: storey198,
  199: storey199,
  200: storey200,
  201: storey201,
  202: storey202,
  203: storey203,
  204: storey204,
  205: storey205,
  206: storey206,
  207: storey207,
  208: storey208,
  209: storey209,
  210: storey210,
  211: storey211,
  212: storey212,
  213: storey213,
  214: storey214,
  215: storey215,
  216: storey216,
  217: storey217,
  218: storey218,
  219: storey219,
  220: storey220,
  221: storey221,
  222: storey222,
  223: storey223,
  224: storey224,
  225: storey225,
  226: storey226,
  227: storey227,
  228: storey228,
  229: storey229,
  230: storey230,
  231: storey231,
  232: storey232,
  233: storey233,
  234: storey234,
  235: storey235,
  236: storey236,
  237: storey237,
  238: storey238,
  239: storey239,
  240: storey240,
  241: storey241,
  242: storey242,
  243: storey243,
  244: storey244,
  245: storey245,
  246: storey246,
  247: storey247,
  248: storey248,
  249: storey249,
  250: storey250,
  251: storey251,
  252: storey252,
  253: storey253,
  254: storey254,
  255: storey255,
  256: storey256,
  257: storey257,
  258: storey258,
  259: storey259,
  260: storey260,
  261: storey261,
  262: storey262,
  263: storey263,
  264: storey264,
  265: storey265,
  266: storey266,
  267: storey267,
  268: storey268,
  269: storey269,
  270: storey270,
  271: storey271,
  272: storey272,
  273: storey273,
  274: storey274,
  275: storey275,
  276: storey276,
  277: storey277,
  278: storey278,
  279: storey279,
  280: storey280,
  281: storey281,
  282: storey282,
  283: storey283,
  284: storey284,
  285: storey285,
  286: storey286,
  287: storey287,
  288: storey288,
  289: storey289,
  290: storey290,
  291: storey291,
  292: storey292,
  293: storey293,
  294: storey294,
  295: storey295,
  296: storey296,
  297: storey297,
  298: storey298,
  299: storey299,
  300: storey300,
};

const ClosedCard = ({ id, handleOpen }) => {
  const fallbackImage = storey1;
  const [imageSrc, setImageSrc] = useState(
    imageDictionary[id] || fallbackImage
  );

  useEffect(() => {
    setImageSrc(imageDictionary[id] || fallbackImage);
  }, [id]);

  const handleImageError = () => {
    console.error(`Failed to load image at path: ${imageSrc}`);
    setImageSrc(fallbackImage);
  };

  return (
    <Card
      sx={{
        width: "100%", // Set the card to take the full width of its container
        maxWidth: 600, // Set a maximum width if needed
        cursor: "pointer",
        border: "2px solid",
        borderColor: "primary.main",
      }}
      onClick={handleOpen}
    >
      <CardMedia
        component="img"
        image={imageSrc}
        alt={`Apartment ${id}`}
        onError={handleImageError}
        sx={{
          backgroundColor: "#86c1d1", 
          width: "100%",
          height: "auto",
          transition: "background-color 0.3s ease",
          "&:hover": {
            backgroundColor: "yellow", // Change background color on hover
          },
        }} // Ensure the image maintains its aspect ratio
      />
    </Card>
  );
};

export default ClosedCard;