import { createTheme } from "@mui/material/styles";
import starsImage from "./assets/img/stars.png"; // Importe a imagem de fundo
import cloudsImage from "./assets/img/clouds.png"; // Importe a imagem das nuvens

const theme = createTheme({
  palette: {
    primary: {
      main: "#27405e", // Cor primária
    },
    secondary: {
      main: "#ffff", // Cor secundária
    },
    tertiary: {
      main: "#c4c4c4", // Cor terciária
    },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
    h6: {
      fontWeight: 600,
    },
    body1: {
      fontSize: "1rem",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: `url(${starsImage})`,
          backgroundRepeat: "repeat",
          margin: 0,
          padding: 0,
          overflowX: "hidden",
        },
        "#clouds": {
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%", // Ajuste a altura conforme necessário
          backgroundImage: `url(${cloudsImage})`,
          backgroundRepeat: "repeat-x",
          animation: "moveClouds 60s linear infinite",
          zIndex: -1,
        },
        "@keyframes moveClouds": {
          "0%": { backgroundPosition: "0 0" },
          "100%": { backgroundPosition: "1000px 0" }, // Ajuste a distância conforme necessário
        },
      },
    },
  },
});

export default theme;
