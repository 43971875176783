import WarningIcon from "@mui/icons-material/Warning";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import PrayerIcon from "@mui/icons-material/AccessibilityNew";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";

// Dicionário de ícones que mapeia IDs para rótulos e componentes de ícones
const iconDictionary = {
  1: { label: "CORRA!", icon: <WarningIcon /> },
  2: { label: "NÃO TRABALHE COM", icon: <WorkOffIcon /> },
  3: { label: "NÃO É BOM ORAR COM", icon: <PrayerIcon /> },
  4: { label: "NÃO NAMORE", icon: <HeartBrokenIcon /> },
};

export default iconDictionary;
